var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        { staticClass: "partition-area" },
        [
          _c(
            "el-form",
            { attrs: { "label-suffix": _vm.constants.labelSuffix } },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "配置合同" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "font-weight": "bold" },
                      attrs: { span: 7, offset: 5 }
                    },
                    [
                      _c("el-form-item", { attrs: { label: "模型键值" } }, [
                        !this._.isEmpty(_vm.userTaskList)
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      _vm.userTaskList[0].flowKey
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "font-weight": "bold" },
                      attrs: { span: 7, offset: 1 }
                    },
                    [
                      _c("el-form-item", { attrs: { label: "模型名称" } }, [
                        !this._.isEmpty(_vm.userTaskList)
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      _vm.userTaskList[0].flowName
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "overflow-div" }, [
                      _c(
                        "div",
                        { staticClass: "el-steps el-steps--horizontal" },
                        _vm._l(_vm.userTaskList, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "el-step is-horizontal is-center",
                              staticStyle: {
                                "flex-basis": "50%",
                                "margin-right": "0px"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "el-step__head is-process" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-step__line",
                                      staticStyle: {
                                        "margin-right": "0px",
                                        "margin-top": "15px"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-step__line-inner",
                                        staticStyle: {
                                          "transition-delay": "0ms",
                                          "border-width": "0px",
                                          width: "0%"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-step__icon",
                                      staticStyle: {
                                        "margin-top": "15px",
                                        "font-size": "17px",
                                        width: "75px"
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "diamond" }, [
                                        _vm._v(_vm._s(item.userTaskName))
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-step__main",
                                  staticStyle: { "margin-top": "25px" }
                                },
                                [
                                  _c("div", { staticClass: "el-step__title" }),
                                  _c("div", {
                                    staticClass:
                                      "el-step__description is-process"
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "5px",
                                    "margin-top": "15px"
                                  }
                                },
                                [
                                  _c(
                                    "el-collapse",
                                    {
                                      attrs: { accordion: "" },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value: _vm.activeNames,
                                        callback: function($$v) {
                                          _vm.activeNames = $$v
                                        },
                                        expression: "activeNames"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "btn-inner" }, [
                                        _c("span", [
                                          _vm._v("   "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-btn",
                                              staticStyle: {
                                                "font-size": "14px"
                                              },
                                              attrs: { href: "javascript:" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.clickDialog(item)
                                                }
                                              }
                                            },
                                            [_vm._v("新增")]
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "el-collapse-item",
                                        {
                                          attrs: {
                                            title:
                                              "任务节点：" + item.userTaskName,
                                            name: item.userTaskId
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                height: "220px",
                                                "overflow-y": "scroll"
                                              }
                                            },
                                            _vm._l(_vm.contractList, function(
                                              item1,
                                              key
                                            ) {
                                              return _c(
                                                "span",
                                                { key: key },
                                                [
                                                  _c("el-col", [
                                                    key === 0
                                                      ? _c("p", {
                                                          staticStyle: {
                                                            "margin-top": "20px"
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _c("p", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "14px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item1.contractName
                                                            ) + " "
                                                          ),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "text-btn danger",
                                                              attrs: {
                                                                href:
                                                                  "javascript:"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteContract(
                                                                    item1
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-delete"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    key ===
                                                    _vm.contractList.length - 1
                                                      ? _c("br")
                                                      : _vm._e(),
                                                    key !==
                                                    _vm.contractList.length - 1
                                                      ? _c("div", {
                                                          staticClass:
                                                            "el-divider el-divider--horizontal"
                                                        })
                                                      : _vm._e()
                                                  ])
                                                ],
                                                1
                                              )
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _c("br")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "ics-dialog-inner",
        {
          attrs: {
            visible: _vm.dialog.visible,
            title: _vm.dialog.title,
            "submit-title": "提交"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
            submit: _vm.submitSelection
          }
        },
        [
          _c(
            "div",
            { staticClass: "partition-table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingTem.list,
                      expression: "loadingTem.list"
                    }
                  ],
                  ref: "multipleTable",
                  staticClass: "table-input",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.templateList,
                    border: "",
                    "max-height": "360"
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      align: "left",
                      "min-width": "50"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "templateCode",
                      label: "模板编号",
                      "min-width": "140",
                      formatter: _vm.utils.isEffective
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contractName",
                      label: "模板名称",
                      "min-width": "140",
                      formatter: _vm.utils.isEffective
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }