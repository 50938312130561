<template>
  <el-main>
    <page-title show-back-btn />
    <div class="partition-area">
      <el-form :label-suffix="constants.labelSuffix">
        <ics-page-inner title="配置合同">
          <el-col :span="7" :offset="5" style="font-weight: bold;">
            <el-form-item label="模型键值">
              <p v-if="!this._.isEmpty(userTaskList)">
                {{ utils.isEffectiveCommon(userTaskList[0].flowKey) }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="1" style="font-weight: bold;">
            <el-form-item label="模型名称">
              <p v-if="!this._.isEmpty(userTaskList)">
                {{ utils.isEffectiveCommon(userTaskList[0].flowName) }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="overflow-div">
              <div class="el-steps el-steps--horizontal">
                <div v-for="(item, index) in userTaskList" :key="index" class="el-step is-horizontal is-center" style="flex-basis: 50%; margin-right: 0px;">
                  <div class="el-step__head is-process">
                    <div class="el-step__line" style="margin-right: 0px;margin-top: 15px;">
                      <i class="el-step__line-inner" style="transition-delay: 0ms; border-width: 0px; width: 0%;" />
                    </div>
                    <div class="el-step__icon" style="margin-top: 15px; font-size: 17px; width: 75px;">
                      <span class="diamond">{{ item.userTaskName }}</span>
                    </div>
                  </div>
                  <div class="el-step__main" style="margin-top: 25px;">
                    <div class="el-step__title" />
                    <div class="el-step__description is-process" />
                  </div>
                  <div style="margin-left: 5px; margin-top: 15px;">
                    <el-collapse v-model="activeNames" accordion @change="handleChange">
                      <div class="btn-inner">
                        <span>
                          &nbsp;
                          <a href="javascript:" class="text-btn" style="font-size: 14px;" @click="clickDialog(item)">新增</a>
                        </span>
                      </div>
                      <el-collapse-item :title="`任务节点：${item.userTaskName}`" :name="item.userTaskId">
                        <div style="width: 100%; height: 220px; overflow-y: scroll;">
                          <span v-for="(item1, key) in contractList" :key="key">
                            <el-col>
                              <p v-if="key===0" style="margin-top: 20px;" />
                              <p>
                                <span style=" font-size: 14px;">{{ item1.contractName }}
                                  <a href="javascript:" class="text-btn danger" @click="deleteContract(item1)"><i class="el-icon-delete" /></a>
                                </span>
                              </p>
                              <br v-if="key===contractList.length-1">
                              <div v-if="key!==contractList.length-1" class="el-divider el-divider--horizontal" />
                            </el-col>
                          </span>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </div>
              </div>
              <br>
            </div>
          </el-col>
        </ics-page-inner>
      </el-form>
    </div>
    <ics-dialog-inner :visible.sync="dialog.visible" :title="dialog.title" submit-title="提交" @submit="submitSelection">
      <div class="partition-table">
        <el-table ref="multipleTable" v-loading="loadingTem.list" :data="templateList" border style="width: 100%" class="table-input" max-height="360" @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="left" min-width="50" />
          <el-table-column prop="templateCode" label="模板编号" min-width="140" :formatter="utils.isEffective" />
          <el-table-column prop="contractName" label="模板名称" min-width="140" :formatter="utils.isEffective" />
        </el-table>
      </div>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
import IcsPageInner from "../../../components/page-inner";
export default {
  components: {IcsPageInner, IcsDialogInner},
  mixins: [routeEnterMixin],
  data () {
    return {
      id: this.$route.query.id,
      productCode: this.$route.query.productCode,
      activeIndex: '',
      userTaskList: [],
      contractList: [],
      activeNames: '',
      templateList: [],
      theUserTask: {},
      multipleSelection: [],
      loadingTem: {
        list: false
      },
      dialog: {
        visible: false,
        title: '合同列表'
      }
    }
  },
  created () {
    if (this.id) {
      this.getUserTask()
    }
  },
  methods: {
    // 使用index关联起来
    stpeBtn(index){
      this.blueColor=index
      this.blueJColor=index
    },
    getUserTask () {
      this.api.workflow.getUserTask(this.id).then(result => {
        const data = result.data.data || []
        this.userTaskList = data
      }).finally(() => {
        this.loading.list = false
      })
    },
    handleChange(val) {
      this.getContractList(val)
    },
    clickDialog (row) {
      this.dialog.visible = true
      this.theUserTask = row || {}
      this.getProductContract()
    },
    getProductContract () {
      this.loadingTem.list = true
      this.api.workflow.getProductTemplate(this.productCode, this.theUserTask.flowKey).then(result => {
        const data = result.data.data
        this.templateList = data || []
      }).finally(() => {
        this.loadingTem.list = false
      })
    },
    getContractList (userTaskId) {
      this.api.workflow.getContractList(userTaskId).then(result => {
        this.contractList = result.data.data
      }).finally(() => {
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    deleteContract (row) {
      this.$confirm('此操作将删除该合同, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        callback: () => {},
        beforeClose: (action, ctx, close) => {
          if (action !== 'confirm') {
            close()
            return
          }
          ctx.confirmButtonLoading = true
          this.api.workflow.deleteContract(row.id).then(result => {
            if (result.data.success === true) {
              this.$message.success('操作成功')
              this.getContractList(this.activeNames)
              close()
            } else {
              this.$message.error(result.data.message)
              close()
            }
          }).finally(() => {
            ctx.confirmButtonLoading = false;
          })
        }
      }).finally(() => {})
    },
    submitSelection () {
      if (this._.isEmpty(this.multipleSelection)) {
        this.$message.error('请选择合同')
        return false
      }
      const data = []
      this.multipleSelection.forEach(item => {
        data.push({
          contractCode: item.templateCode,
          contractName: item.contractName,
          flowKey: this.theUserTask.flowKey,
          flowName: this.theUserTask.flowName,
          userTaskId: this.theUserTask.userTaskId,
          userTaskName: this.theUserTask.userTaskName,
        })
      })
      this.api.workflow.addContract(data).then(result => {
        if (result.data.success === true) {
          this.loading.submit = false
          this.$message.success('操作成功')
          this.getUserTask()
          this.getContractList(this.activeNames)
          this.dialog.visible = false
          this.multipleSelection = []
          this.theUserTask = {}
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.submit = false
      })
    }
  }
}
</script>

<style scoped>
.overflow-div{
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.diamond{
  border: black solid 1px;
  width: 350px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}
.step_item_box:last-child .step_triangle {
  border: none;
}
.show_length{
  width:200px;
  text-overflow :ellipsis;
  white-space :nowrap;
  overflow : hidden;
}
</style>
